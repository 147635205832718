(function ($) {
    
    function categoriesHome() {
        $('.categories .tile').click(function() {
            var clickedElem = $(this);
            var categoryName = $(this).attr('category');

            if( $(this).hasClass('active') ) {
                $('.categories .tile').removeClass('active');
                $('.sub-category').slideUp();
            }
            else {
                $('.categories .tile').removeClass('active');
                $(this).addClass('active');
    
                $('.sub-category').slideUp();
                setTimeout(function() {
                    if(isMobile()) {
                        var subcats = $('.cat-' + categoryName).wrap('<div>').parent().html();
                        $('.cat-' + categoryName).remove();

                        $(clickedElem).parent().after('<div class="col-12">' + subcats + '</div>').html();
                    }

                    $('.cat-' + categoryName).slideDown();

                    setTimeout(function() {
                        $("html, body").animate({ scrollTop: $('.cat-' + categoryName).offset().top - 190 }, 500);
                    }, 500);
                }, 500);

                // $("html, body").animate({ scrollTop: $('.cat-' + categoryName).offset().top + 10 }, 800);
                // return false;
            }
        });
    }

    function categoriesMenu() {
        var categoriesMenuStatus = false;
        $('.categories-toggle').click(function() {
            if(categoriesMenuStatus) {
                $('.menu-categories').slideUp();
                $('.categories-button i').removeClass('fa-angle-double-up');
                $('.categories-button i').addClass('fa-angle-double-down');
                categoriesMenuStatus = false;
            }
            else {
                $('.menu-categories').slideDown();
                $('.categories-button i').removeClass('fa-angle-double-down');
                $('.categories-button i').addClass('fa-angle-double-up');
                categoriesMenuStatus = true;
            }
        });

        $('.menu-categories__categories .button').click(function() {
            var categoryName = $(this).attr('category');

            if( $(this).hasClass('active') ) {
                $('.menu-categories__categories .button').removeClass('active');
                $('.menu-categories__subcategory').slideUp();
            }
            else {
                $('.menu-categories__categories .button').removeClass('active');
                $(this).addClass('active');
    
                $('.menu-categories__subcategory').slideUp();
                setTimeout(function() {
                    $('.menu-category-' + categoryName).slideDown();
                }, 500);
            }
        });
        
        $( ".menu-categories__categories .button" ).each(function( index ) {
            if( $(this).hasClass('active') ) {
                var categoryName = $(this).attr('category');
                
                $('.menu-category-' + categoryName).slideDown();
            }
        });
    }

    function tabs() {
        $('.tabs .tab:first-child').addClass('active');
        $('.tabs-contents .tab-content:first-child').fadeIn();

        $('.tabs .tab').click(function() {
            $('.tabs .tab').removeClass('active');
            $('.tab-content').fadeOut();

            var content = $(this).attr('tab-content');
            $(this).addClass('active');
            setTimeout(function() {
                $('.tab-content-' + content).fadeIn();
            }, 400);
        });
    }

    function shapes() {
        $('.shape').click(function() {
            var shapeUrl = $(this).attr('shape-url');
            $('.product__image .tile__image img').attr('src', shapeUrl);

            $("html, body").animate({ scrollTop: $('.product__image').offset().top - 10 }, 500);
        });
    }

    function realizationsGallery() {
        $('.gallery').slickLightbox({
            itemSelector        : 'a',
            navigateByKeyboard  : true
        });
    }

    categoriesHome();
    categoriesMenu();

    if( $('.main').hasClass('product') ) {
        tabs();
        realizationsGallery();
        shapes();
    }


    /* Helpers */
    function isMobile() {
        return $(window).width() < 767 ? true : false;
    }

})(jQuery);
